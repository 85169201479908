<template>
  <app-header/>
    <Suspense>
      <template #default>
        <app-games-details :gid='gid'/>
      </template>
      <template #fallback>
        <app-loading />
      </template>
    </Suspense>
   <app-footer/>
</template>

<script>
import AppFooter from '../../components/layouts/AppFooter.vue'
import AppHeader from '../../components/layouts/AppHeader.vue'
import AppGamesDetails from '../../components/Pages/AppGamesDetails.vue'
import AppLoading from '../../components/Spinners/AppLoading.vue'
import { Suspense } from 'vue'
export default {
  components: { AppHeader, AppGamesDetails, AppFooter, AppLoading, Suspense },
  props: {
    gid: String
  },
   name: 'GamesDetails'
}
</script>

<style>

</style>